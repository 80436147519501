import * as R from 'ramda'
import {Link} from 'gatsby'
import Button from '@mui/material/Button'
import classNames from 'classnames'
import React from 'react'

import useStyles from './styles'

const HashTagButtons = ({
  countries,
  industries,
  type,
  services,
  disabled,
  hasNoMargin,
}) => {
  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)
  const industryTags = industries && R.slice(0, 3, industries)
  const servicesTags = services && R.slice(0, 3, services)

  const renderTagButton = (tag, className, disabledClass, index) => (
    <Button
      className={classNames(classes.tag, className, {
        [disabledClass]: disabled,
      })}
      size="small"
      key={index}
    >
      #{tag.name || tag.name.name}
    </Button>
  )

  const renderCountriesTags = countries
    ? () =>
        mapIndexed((tag, index) => {
          if (!tag || !(tag.name || tag.name.name)) {
            return null
          }

          return disabled ? (
            renderTagButton(
              tag,
              classes.countrieTags,
              classes.countrieTagsDisabled,
              index,
            )
          ) : (
            <Link to={`../#/${tag.name || tag.name.name}`}>
              {renderTagButton(
                tag,
                classes.countrieTags,
                classes.countrieTagsDisabled,
                index,
              )}
            </Link>
          )
        }, countries)
    : null

  const renderIndustryTags = industryTags
    ? () =>
        mapIndexed((tag, index) => {
          if (!tag || !(tag.name || tag.name.name)) {
            return null
          }

          return disabled ? (
            renderTagButton(
              tag,
              classes.industrieTags,
              classes.industrieTagsDisabled,
              index,
            )
          ) : (
            <Link to={`../#/${tag.name || tag.name.name}`}>
              {renderTagButton(
                tag,
                classes.industrieTags,
                classes.industrieTagsDisabled,
                index,
              )}
            </Link>
          )
        }, industryTags)
    : null

  const renderTypeTags = tagType =>
    disabled ? (
      <span>
        {renderTagButton(tagType, classes.typeTags, classes.typeTagsDisabled)}
      </span>
    ) : (
      <Link to={`../#/${tagType.name}`}>
        {renderTagButton(tagType, classes.typeTags, classes.typeTagsDisabled)}
      </Link>
    )

  const renderServicesTags = services
    ? () =>
        mapIndexed((tag, index) => {
          if (!tag || !(tag.name || tag.name.name)) {
            return null
          }

          return disabled ? (
            renderTagButton(
              tag,
              classes.serviceTags,
              classes.serviceTagsDisabled,
              index,
            )
          ) : (
            <Link to={`../#/${tag.name || tag.name.name}`}>
              {renderTagButton(
                tag,
                classes.serviceTags,
                classes.serviceTagsDisabled,
                index,
              )}
            </Link>
          )
        }, servicesTags)
    : null

  return (
    <div style={{margin: hasNoMargin && 0}} className={classes.tagsContainer}>
      {countries ? renderCountriesTags(countries) : ''}
      {industries ? renderIndustryTags(industries) : ''}
      {type ? renderTypeTags(type) : ''}
      {services ? renderServicesTags(services) : ''}
    </div>
  )
}

HashTagButtons.propTypes = {
  countries: R.is(Array),
  disabled: R.is(Boolean),
  industries: R.is(Array),
  services: R.is(Array),
  type: R.is(Object),
}

HashTagButtons.defaultProps = {
  countries: null,
  disabled: false,
  industries: null,
  services: null,
  type: null,
}

export default HashTagButtons
