import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(
  ({palette, fontSizes, breakpoints, layout, spacing}) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    tagsContainer: {
      display: 'flex',
      justifyContent: 'left',
      flexWrap: 'wrap',
      marginTop: spacing(2),
      '& Button': {
        margin: 3,
      },
      [breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    tag: {
      textTransform: 'none',
      fontSize: fontSizes.small,
      maxHeight: layout.tags.maxHeight,
    },
    serviceTags: {
      color: palette.tags.departments.color,
      backgroundColor: palette.tags.departments.backgroundColor,
    },
    countrieTags: {
      color: palette.tertiary.main,
      backgroundColor: palette.tags.partners.backgroundColor,
    },
    industrieTags: {
      color: palette.secondary.main,
      backgroundColor: palette.tags.industries.backgroundColor,
    },
    typeTags: {
      color: palette.tertiary.main,
      backgroundColor: palette.tags.partners.backgroundColor,
    },
    countrieTagsDisabled: {
      color: palette.tertiary.main,
      backgroundColor: palette.tags.partners.backgroundColor,
      '&:hover': {
        backgroundColor: palette.tags.partners.backgroundColor,
      },
    },
    serviceTagsDisabled: {
      color: palette.tags.departments.color,
      backgroundColor: palette.tags.departments.backgroundColor,
      '&:hover': {
        backgroundColor: palette.tags.departments.backgroundColor,
      },
    },
    industrieTagsDisabled: {
      color: palette.secondary.main,
      backgroundColor: palette.tags.industries.backgroundColor,
      '&:hover': {
        backgroundColor: palette.tags.industries.backgroundColor,
      },
    },
    typeTagsDisabled: {
      color: palette.tertiary.main,
      backgroundColor: palette.tags.partners.backgroundColor,
      '&:hover': {
        backgroundColor: palette.tags.partners.backgroundColor,
      },
    },
  }),
)

export default useStyles
