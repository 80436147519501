import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, fontSizes, breakpoints, spacing}) => ({
  heroText: {
    width: '50%',
    '& > p': {
      paddingTop: spacing(2),
      color: palette.background.lightDark,
    },
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    },
  },
  heroBlogPosts: {
    display: 'flex',
    marginTop: spacing(10),
    marginBottom: spacing(10),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 0,
    },
  },
  heroBlogPostContainer: {
    width: '50%',
    paddingRight: spacing(5),
    textDecoration: 'none',
    color: 'inherit',
    [breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 0,
      marginTop: spacing(5),
    },
  },
  blogPost: {
    width: '100%',
    height: '310px',
    borderRadius: '40px 7px',
    transform: 'translateZ(0)',
    objectFit: 'contain',
    marginBottom: spacing(3),
    [breakpoints.down('sm')]: {
      height: '210px',
    },
  },
  blogContainer: {
    marginTop: spacing(6),
  },
  description: {
    marginTop: spacing(10),
  },
  type: {
    color: palette.primary.main,
    fontFamily: 'CamptonSemiBold',
    fontSize: fontSizes.medium,
  },
  metaTitle: {
    marginTop: spacing(-0.5),
    fontFamily: 'CamptonMedium',
    fontSize: 20,
  },
  date: {
    fontFamily: 'CamptonLight',
    fontSize: '16px',
    color: palette.text.grey,
  },
  metaDescription: {
    fontFamily: 'CamptonLight',
  },

  blogPostInfo: {
    display: 'flex',
    height: 'fit-content',
    gap: 8,
    alignItems: 'baseline',
  },
}))

export default useStyles
