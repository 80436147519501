import * as R from 'ramda'

import {itemsQuery, itemsSysId} from 'helpers/filters/insights/itemsQuery'

export const serviceFilter = (
  url,
  request,
  locale,
  tags,
  hook,
  countryInsights,
) => {
  fetch(
    url,
    request(`
      {
        serviceCollection(
        locale: ${JSON.stringify(locale)}, 
        where: {name_in: ${JSON.stringify(tags)}}) {
          ${itemsSysId}
        }
      }
  `),
  )
    .then(response => response.json())
    .then(response =>
      fetch(
        url,
        request(`
          {
            serviceCollection(
            where: {sys: {id_in: ${JSON.stringify(
              R.map(el => el.sys.id, response.data.serviceCollection.items),
            )}}}) {
              ${itemsSysId}
            }
          }
      `),
      ),
    )
    .then(res => res.json())
    .then(res =>
      fetch(
        url,
        request(`
          {
            insightCollection(
              locale: ${JSON.stringify(locale)}, 
              limit: 1000,
              order: publicationDate_DESC,
              where: {sys: {id_in: ${JSON.stringify(
                R.map(
                  e => e.contentful_id,
                  R.filter(
                    e => e !== false && e !== null,
                    R.flatten(
                      R.map(
                        el =>
                          el.services2 &&
                          R.map(
                            e =>
                              R.includes(
                                e.contentful_id,
                                R.map(
                                  elem => elem.sys.id,
                                  res.data.serviceCollection.items,
                                ),
                              ) && el,
                            el.services2,
                          ),
                        countryInsights,
                      ),
                    ),
                  ),
                ).slice(0, 275),
              )}}}) {
              ${itemsQuery}
            }
          }
      `),
      )
        .then(respo => respo.json())
        .then(respo => hook(respo.data.insightCollection.items)),
    )
}
