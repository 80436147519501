import {bool, func, string} from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import classNames from 'classnames'
import React from 'react'

import RoundButton from 'components/UI/RoundButton'

import useStyles from './styles'

const LoadMore = ({
  label,
  loadMore,
  hasMoreItems,
  infiniteScroll,
  handleLoadMore,
  hasPaddingTop,
}) => {
  const classes = useStyles()

  return (
    <div className={classNames({[classes.topPadding]: hasPaddingTop})}>
      {loadMore && hasMoreItems && (
        <CircularProgress className={classes.centered} />
      )}
      {!loadMore && !infiniteScroll && (
        <div className={classes.centered}>
          <RoundButton isAction isCentered arrowDown action={handleLoadMore}>
            {label}
          </RoundButton>
        </div>
      )}
    </div>
  )
}

LoadMore.propTypes = {
  handleLoadMore: func,
  hasMoreItems: bool,
  hasPaddingTop: bool,
  infiniteScroll: bool,
  label: string,
  loadMore: bool,
}
LoadMore.defaultProps = {
  handleLoadMore: null,
  hasMoreItems: false,
  hasPaddingTop: false,
  infiniteScroll: false,
  label: ' ',
  loadMore: false,
}

export default LoadMore
