import {useEffect, useState} from 'react'

export default function useObserver(loadRef, func) {
  const [state, setState] = useState(false)

  const handleObserver = entities => {
    const [target] = entities

    if (state && target.isIntersecting) {
      func()
    }
  }

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '10px',
      threshold: 1.0,
    }

    const observer = new IntersectionObserver(handleObserver, options)

    if (state && loadRef.current) {
      observer.observe(loadRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [state, loadRef])

  return [state, setState]
}
