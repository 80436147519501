import * as R from 'ramda'

import {itemsQuery, itemsSysId} from 'helpers/filters/insights/itemsQuery'

export const typeFilter = async (
  url,
  request,
  hook,
  locale,
  countryInsightsIds,
  tags,
) => {
  await fetch(
    url,
    request(` 
      {
        updateTypeCollection(
        locale: ${JSON.stringify(locale)}, 
        where: {name_in: ${JSON.stringify(tags)}}) {
          ${itemsSysId}
        }
      }
    `),
  )
    .then(response => response.json())
    .then(response =>
      fetch(
        url,
        request(`
          {
            updateTypeCollection(
            where: {sys: {id_in: ${JSON.stringify(
              R.map(el => el.sys.id, response.data.updateTypeCollection.items),
            )}}}) {
              ${itemsSysId}
            }
          }
        `),
      ),
    )
    .then(res => res.json())
    .then(res =>
      fetch(
        url,
        request(`
        {
          insightCollection(
          limit: 1000,
          locale: ${JSON.stringify(locale)}, 
          order: publicationDate_DESC,
          where: {type: {sys: {id: ${JSON.stringify(
            res.data.updateTypeCollection.items[0].sys.id,
          )}}}, sys: {id_in: ${JSON.stringify(
          countryInsightsIds.slice(0, 275),
        )}}}) {
            total
            ${itemsQuery}
          }
        }
        `),
      )
        .then(respo => respo.json())
        .then(respo => hook(respo.data.insightCollection.items)),
    )
}
