export const itemsQuery = `
items {
  title
  slug
  metaTitle
  metaDescription
  calToAction
  externalLink
  publicationDate
  image {
    url(transform: {width: 800, height: 450, resizeStrategy: FILL, resizeFocus: CENTER, format: WEBP, quality: 100})
  }
  type {
    name
  }
}
`

export const itemsSysId = `
items {
  sys {
    id
  }
}
`
