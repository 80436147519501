import * as R from 'ramda'
import {Link} from 'gatsby'
import React from 'react'

import {heroInsightsPropTypes} from 'helpers/propTypes'
import Description from 'components/UI/Description'
import HashTagButtons from 'components/UI/HashTagButtons'
import Media from 'components/UI/Media'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

import useIsMobile from 'hooks/useIsMobile'
import useStyles from './styles'

const Hero = ({
  pageTitle,
  shortDescription,
  nodes,
  titleVariant = 'h2',
  featuredTagLabel,
}) => {
  const isMobile = useIsMobile()
  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)

  const nodeNotNull = R.filter(node => (node.image ? node : null), nodes)

  const renderLinkByWebsite = node =>
    process.env.GATSBY_WEBSITE === 'keyrus-fondation'
      ? `../news/${node.slug}`
      : `../insights/${node.slug}`

  const featuredTag = [{name: featuredTagLabel}]

  const returnHeroBlog = () =>
    nodes &&
    mapIndexed(node => {
      const id = R.pathOr('', ['id'], node)
      const type = R.pathOr({}, ['type'], node)
      const isFeatured = R.pathOr(false, ['isFeatured'], node)
      const title = R.pathOr('', ['title'], node)
      const publicationDate = R.pathOr('', ['publicationDate'], node)
      const metaDescription = R.pathOr(
        {},
        ['metaDescription', 'metaDescription'],
        node,
      )
      const image = R.pathOr(null, ['image', 'gatsbyImageData'], node)
      const name = R.pathOr('', ['name'], type)

      return (
        image && (
          <Link
            to={renderLinkByWebsite(node)}
            className={classes.heroBlogPostContainer}
            key={id}
          >
            <Media data={image} className={classes.blogPost} />
            <div className={classes.blogPostInfo}>
              <p className={classes.type}>{name}</p>
              {isFeatured && (
                <HashTagButtons hasNoMargin disabled industries={featuredTag} />
              )}
            </div>
            <h2 className={classes.metaTitle}>{title}</h2>
            <p className={classes.date}>{publicationDate}</p>
            <p className={classes.metaDescription}>{metaDescription}</p>
          </Link>
        )
      )
    }, nodeNotNull)

  return (
    <Section hasPaddingBottom={false} hasVerySmallPadding>
      <div className={classes.heroText}>
        <Title variant={titleVariant} type="mainTitle" isCentered={!!isMobile}>
          {pageTitle}
        </Title>
        <Description type="largeDescription">{shortDescription}</Description>
      </div>
      <div className={classes.heroBlogPosts}>{returnHeroBlog()}</div>
    </Section>
  )
}

Hero.propTypes = heroInsightsPropTypes

Hero.defaultProps = {}

export default Hero
