import * as R from 'ramda'
import {useEffect, useState} from 'react'

export default function useHashReader(location) {
  const [hashConditions, setHashConditions] = useState({
    allPartnersSelected: false,
    allCountriesSelected: false,
    onePartnerSelected: false,
    multiPartnersSelected: false,
    hashIsEmpty: false,
  })

  const {hash} = location

  const countOccurences = (string, word) => R.length(R.split(word, string)) - 1

  useEffect(() => {
    const multiPartnersSelected = countOccurences(hash, 'partner-') > 1
    const onePartnerSelected = countOccurences(hash, 'partner-') === 1
    const hashIsEmpty = R.replace('#/', '', hash) === ''
    const allPartnersSelected =
      R.includes('all-partners', hash) || R.includes('technology', hash)
    const allCountriesSelected = R.includes('All%20countries', hash)

    if (allCountriesSelected) {
      setHashConditions(prevState => ({
        ...prevState,
        allCountriesSelected: true,
      }))
    } else {
      setHashConditions(prevState => ({
        ...prevState,
        allCountriesSelected: false,
      }))
    }
    if (allPartnersSelected) {
      setHashConditions(prevState => ({
        ...prevState,
        allPartnersSelected: true,
      }))
    } else {
      setHashConditions(prevState => ({
        ...prevState,
        allPartnersSelected: false,
      }))
    }
    if (onePartnerSelected) {
      setHashConditions(prevState => ({
        ...prevState,
        onePartnerSelected: true,
      }))
    } else {
      setHashConditions(prevState => ({
        ...prevState,
        onePartnerSelected: false,
      }))
    }
    if (multiPartnersSelected) {
      setHashConditions(prevState => ({
        ...prevState,
        multiPartnersSelected: true,
      }))
    } else {
      setHashConditions(prevState => ({
        ...prevState,
        multiPartnersSelected: false,
      }))
    }
    if (hashIsEmpty) {
      setHashConditions(prevState => ({
        ...prevState,
        hashIsEmpty: true,
      }))
    } else {
      setHashConditions(prevState => ({
        ...prevState,
        hashIsEmpty: false,
      }))
    }
  }, [hash])

  return [hashConditions]
}
