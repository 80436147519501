import {itemsQuery} from 'helpers/filters/insights/itemsQuery'

export const retrieveDefaultInsights = async (
  url,
  request,
  hook,
  locale,
  countryInsightsIds,
) => {
  if (countryInsightsIds) {
    await fetch(
      url,
      request(`
        {
          insightCollection(
          limit: 1000,
          locale: ${JSON.stringify(locale)}, 
          where: {sys: {id_in: ${JSON.stringify(
            countryInsightsIds.slice(0, 275),
          )}}}, 
          order: publicationDate_DESC,
          skip: 0,
          ) {
            ${itemsQuery}
          }
        } 
    `),
    )
      .then(response => response.json())
      .then(response => hook(response.data.insightCollection.items))
  }
}
