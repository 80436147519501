import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, fontSizes, breakpoints, spacing}) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '100px',
  },
  blogContainer: {
    display: 'flex',
    paddingRight: spacing(4),
    paddingBottom: spacing(4),
    textDecoration: 'none',
    color: 'inherit',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingBottom: spacing(7),
      paddingRight: 0,
      maxWidth: '90%',
    },
  },
  blogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& *': {
      margin: 0,
      paddingBottom: spacing(1),
    },
  },
  type: {
    margin: 0,
    color: palette.primary.main,
    fontFamily: 'CamptonSemiBold',
    fontSize: fontSizes.description,
  },
  card: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: spacing(1),
    cursor: 'pointer',
    minWidth: 352,
    [breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    '& img': {
      maxWidth: 320,
      height: 180,
      objectFit: 'cover',
      borderRadius: spacing(1),
      marginRight: spacing(4),
      [breakpoints.down('sm')]: {
        marginBottom: spacing(3),
        marginRight: 0,
        height: 'auto',
        maxWidth: '100%',
      },
    },
  },
  blogImage: {
    minWidth: '260px',
    minHeight: '210px',
    borderRadius: spacing(1),
    marginRight: spacing(4),
    [breakpoints.down('sm')]: {
      marginBottom: spacing(2),
      marginRight: 0,
    },
  },
  metaTitle: {
    fontSize: 20,
  },
  date: {
    fontSize: 16,
    fontFamily: 'CamptonBook',
    color: palette.text.grey,
  },
  metaDescription: {
    fontFamily: 'CamptonLight',
    fontSize: fontSizes.description,
  },
  filtersContainer: {
    width: '17%',
  },
  blogListRoot: {
    [breakpoints.up('md')]: {
      flex: 1,
      maxWidth: 1010,
    },
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}))

export default useStyles
