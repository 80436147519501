import * as R from 'ramda'
import {graphql} from 'gatsby'
import React from 'react'

import {
  allContentfulIndustryPropTypes,
  allContentfulInsightTypePropTypes,
  locationPropTypes,
  pageContextPropTypes,
} from 'helpers/propTypes'
import {formRedirection} from 'helpers/utils'
import {shape} from 'prop-types'
import BlogList from 'components/Insights/BlogList'
import Hero from 'components/Insights/Hero'
import SEO from 'components/seo'
// import SwitchNewsLetterForm from 'components/UI/SwitchNewsLetterForm'
import useInsightsFilterData from 'hooks/useInsightsFilterData'

const Insights = ({pageContext, data, location}) => {
  const {nodeLocale} = pageContext
  const countryData = R.path(['contentfulCountry'], data)
  const {
    countryCode,
    navLocale,
    salesforce,
    newsletterCampaignToken,
    featuredInsights,
  } = R.pathOr('', ['contentfulCountry'], data)

  const {
    pageTitle,
    metaTitle,
    metaDescription: {metaDescription},
    shortDescription: {shortDescription},
    newsletterCallToAction,
    newsletterTitle,
    newsletterDescription,
    newsletterPlaceholder,
    newsletterImage: {file},
    featuredTagLabel,
    filterByIndustryLabel,
    filterByServiceLabel,
    filterByTypeLabel,
    filterSeeMoreLabel,
    filterSeeLessLabel,
    newsletterGdprConsent,
  } = R.pathOr(null, ['allContentfulInsights', 'nodes', 0], data)

  const {searchPlaceholder} = R.pathOr(
    '',
    ['allContentfulPlaybook', 'edges', 0, 'node'],
    data,
  )

  const {
    allContentfulIndustry,
    allContentfulInsightType,
    countryInsights,
    hasTopInsights,
    countryInsightsIds,
    industriesTags,
    siteURL,
    sub,
    top,
    topInsights,
    typesTags,
    taggedServices,
  } = useInsightsFilterData(data)

  const updatedFeaturedInsights =
    featuredInsights && R.map(R.assoc('isFeatured', true), featuredInsights)

  const updatedTopInsights =
    topInsights && R.map(R.assoc('isFeatured', false), topInsights)

  const [index1TopInsight] = updatedTopInsights

  const renderFeaturedArticles = () => {
    switch (R.length(updatedFeaturedInsights)) {
      case 2:
        return updatedFeaturedInsights
      case 1:
        return [...updatedFeaturedInsights, index1TopInsight]
      default:
        return updatedTopInsights
    }
  }

  const formRedirect = formRedirection(
    siteURL,
    countryCode,
    navLocale,
    'newsletter',
  )

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription:
      R.pathOr('', ['metaDescription'], metaDescription) || metaDescription,
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  function removeIdsFromArray(arrayOfIds, arrayOfObjects) {
    const objectIds = arrayOfObjects.map(obj => obj.contentful_id)

    return arrayOfIds.filter(id => !objectIds.includes(id))
  }

  const filteredArrayOfIds = removeIdsFromArray(
    countryInsightsIds,
    renderFeaturedArticles(),
  )

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      {hasTopInsights && (
        <Hero
          pageTitle={pageTitle}
          shortDescription={shortDescription}
          nodes={renderFeaturedArticles()}
          titleVariant="h1"
          featuredTagLabel={featuredTagLabel}
        />
      )}
      {/* <SwitchNewsLetterForm
        campaignToken={newsletterCampaignToken}
        hasLightGreyBackground
        hubSpotCountry={salesforce}
        isArticle={false}
        locale={navLocale}
        location={location}
        newsLetterCallToAction={newsletterCallToAction}
        newsLetterDescription={newsletterDescription}
        newsLetterGdprConsent={newsletterGdprConsent}
        newsLetterImage={file}
        newsLetterPlaceholder={newsletterPlaceholder}
        newsLetterTitle={newsletterTitle}
        redirect={formRedirect}
      /> */}
      {countryInsightsIds && (
        <BlogList
          countryInsights={countryInsights}
          countryInsightsIds={filteredArrayOfIds}
          locale={nodeLocale}
          industry={allContentfulIndustry}
          industriesTags={industriesTags}
          services={taggedServices && R.concat(top, sub)}
          types={allContentfulInsightType}
          typesTags={typesTags}
          searchPlaceholder={searchPlaceholder}
          filterByIndustryLabel={filterByIndustryLabel}
          filterByServiceLabel={filterByServiceLabel}
          filterByTypeLabel={filterByTypeLabel}
          filterSeeMoreLabel={filterSeeMoreLabel}
          filterSeeLessLabel={filterSeeLessLabel}
          pageContext={pageContext}
          countryData={countryData}
          location={location}
        />
      )}
    </>
  )
}

Insights.propTypes = {
  data: shape({
    allContentfulIndustry: allContentfulIndustryPropTypes,
    allContentfulInsightType: allContentfulInsightTypePropTypes,
  }).isRequired,
  location: locationPropTypes.isRequired,
  pageContext: pageContextPropTypes.isRequired,
}

Insights.defaultProps = {}

export const query = graphql`
  query getHeroInfoInsights(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    allContentfulInsights(
      filter: {node_locale: {eq: $nodeLocale}, slug: {regex: $entityRegEx}}
    ) {
      nodes {
        contentful_id
        node_locale
        metaTitle
        metaDescription {
          metaDescription
        }
        pageTitle
        newsletterCallToAction
        newsletterDescription
        newsletterPlaceholder
        newsletterTitle
        featuredTagLabel
        filterByServiceLabel
        filterByIndustryLabel
        filterByTypeLabel
        filterSeeMoreLabel
        filterSeeLessLabel
        shortDescription {
          shortDescription
        }
        searchPlaceholder
        newsletterImage {
          file {
            url
            fileName
          }
        }
        newsletterGdprConsent {
          raw
          references {
            ... on ContentfulSimplePage {
              contentful_id
              slug
              name
            }
          }
        }
      }
    }
    allContentfulInsight(
      filter: {
        country3: {elemMatch: {technicalName: {eq: $technicalName}}}
        node_locale: {eq: $nodeLocale}
      }
      sort: {publicationDate: DESC}
    ) {
      edges {
        node {
          contentful_id
          id
          industries {
            contentful_id
            name {
              name
            }
          }
          services2 {
            contentful_id
            name
          }
        }
      }
    }
    topInsights: allContentfulInsight(
      filter: {
        country3: {elemMatch: {technicalName: {eq: $technicalName}}}
        node_locale: {eq: $nodeLocale}
      }
      sort: {publicationDate: DESC}
      limit: 2
    ) {
      nodes {
        contentful_id
        publicationDate(formatString: "DD.MM.YY")
        node_locale
        title
        image {
          gatsbyImageData(placeholder: BLURRED)
        }
        metaDescription {
          metaDescription
        }
        type {
          name
        }
        slug
      }
    }
    allContentfulPlaybook(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          searchPlaceholder
        }
      }
    }
    allContentfulIndustry(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          name {
            name
          }
          insight {
            id
          }
        }
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    allContentfulInsightType(
      filter: {node_locale: {eq: $nodeLocale}}
      sort: {insight: {publicationDate: DESC}}
    ) {
      edges {
        node {
          name
          insight {
            publicationDate(formatString: "DD.MM.YY")
            image {
              gatsbyImageData(placeholder: BLURRED)
            }
            metaTitle {
              metaTitle
            }
            metaDescription {
              metaDescription
            }
            type {
              name
            }
            slug
          }
        }
      }
    }
    contentfulCountry(
      node_locale: {eq: $nodeLocale}
      technicalName: {eq: $technicalName}
    ) {
      ...countryDataFields
      id
      name
      salesforce
      newsletterCampaignToken
      ...partnerFields
      insight {
        contentful_id
        title
        industries {
          contentful_id
          name {
            name
          }
        }
        services2 {
          contentful_id
          name
        }
        type {
          name
        }
      }
      services {
        topLevelServices {
          entryId
          subLevelServices {
            entryId
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`

export default Insights
