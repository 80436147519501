import * as R from 'ramda'

import {alphabeticalSort, removeDuplicate} from 'helpers/utils'
import {getServices} from 'helpers/services'

export default function useInsightsFilterData(data) {
  const {allContentfulIndustry, allContentfulInsightType} = data
  const siteURL = R.pathOr(null, ['site', 'siteMetadata', 'siteUrl'], data)
  const contentfulCountry = R.pathOr(null, ['contentfulCountry'], data)
  const services = R.pathOr(null, ['services'], contentfulCountry)
  const topLevelServices =
    services && R.pathOr(null, ['topLevelServices'], services)

  const countryInsights = R.map(
    ({node}) => node,
    R.pathOr(null, ['allContentfulInsight', 'edges'], data),
  )

  const countryFeaturedInsights = data.contentfulCountry.featuredInsights

  const contentfulIds =
    countryFeaturedInsights && R.pluck('contentful_id', countryFeaturedInsights)

  const filteredArray =
    contentfulIds &&
    R.reject(
      item => R.contains(item.contentful_id, contentfulIds),
      countryInsights,
    )

  const countryInsightsIds = filteredArray
    ? R.map(node => node.contentful_id, filteredArray)
    : R.map(({node}) => node.contentful_id, data.allContentfulInsight.edges)

  const industriesTags = alphabeticalSort(
    R.filter(
      e => e !== null && e !== undefined,
      removeDuplicate(
        R.flatten(
          R.map(
            el =>
              el.industries &&
              R.map(e => R.pathOr(' ', ['name', 'name'], e), el.industries),
            R.pathOr(' ', ['contentfulCountry', 'insight'], data),
          ),
        ),
      ),
    ),
  )

  const typesTags = alphabeticalSort(
    R.filter(
      e => e !== null && e !== undefined,

      removeDuplicate(
        R.map(
          el => R.pathOr(null, ['type', 'name'], el),
          R.pathOr(' ', ['contentfulCountry', 'insight'], data),
        ),
      ),
    ),
  )

  const allCountryInsights = R.map(
    ({node}) => node,
    R.pathOr('', ['allContentfulInsight', 'edges'], data),
  )

  const taggedServices = removeDuplicate(
    R.flatten(
      R.map(
        node => R.map(el => el.name, R.pathOr('', ['services2'], node)),
        allCountryInsights,
      ),
    ),
  )

  const sub =
    topLevelServices &&
    alphabeticalSort(
      R.flatten(
        R.map(
          el => R.map(e => e.name, el.subLevelServices),
          getServices(data, topLevelServices),
        ),
      ).filter(value => taggedServices.includes(value)),
    )

  const top =
    topLevelServices &&
    alphabeticalSort(
      R.map(el => el.name, getServices(data, topLevelServices)).filter(value =>
        taggedServices.includes(value),
      ),
    )

  const topInsights = data.topInsights.nodes

  const hasTopInsights = data.topInsights.nodes.length !== 0

  return {
    allContentfulIndustry,
    allContentfulInsightType,
    countryInsights,
    countryInsightsIds,
    hasTopInsights,
    industriesTags,
    siteURL,
    sub,
    top,
    topInsights,
    typesTags,
    taggedServices,
  }
}
